.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid green; */
  border-radius: 5px;
  padding-top: 10px;
  background-color: #fff;
  height: 98vh;

  .innerWrapper {
		width: 370px; 
		background-color: white;
		padding: 20px;
		border-radius: 5px;

		.title {
			width: 100%;
			text-align: center;
			font-weight: 500;
			color: #383838;
			margin-bottom: 30px;
		}

		label {
			display: block;
			font-size: 13px;
			margin-bottom: 5px;
		}

		.row {
			width: 100%;
			margin-bottom: 15px;

			input {
				width: 100%;
				height: 35px;
				border: 1px solid #cccccc;
				padding-left: 10px;
				border-radius: 5px;

				&:focus {
					outline: none;
				}
			}
			
			.errorMsg {
				color: #f00;
				font-size: 13px;
			}  
			
		}

		.rowPaymentInput {
			border: 1px solid #cccccc;
			height: 35px;
			padding-top: 8px;
			padding-left: 10px;
			border-radius: 5px;
			margin-bottom: 15px;
		}

		.addressWrapper {
			.rowSelect {
				display: flex;
				width: 100%;
				margin-bottom: 15px;

				input {
					width: 100%;
					position: relative;
					border: 1px solid #cccccc;
					padding-left: 10px;
					height: calc(100% - 21px);
					border-radius: 5px;

					&:focus {
						outline: none;
					}
				}

				> div {
					width: 50%;
				}
				> div:first-child {
					margin-right: 10px;
				}
			}

			.btnContainer {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				margin-top: 30px;

				button {
					cursor: pointer;
					background-color: var(--primary-color);
					width: 150px;
					border: none;
					height: 40px;
					color: white;
					border-radius: 5px;
					font-weight: 500;
				}
			}
			
			.errorMsg {
				color: #f00;
				font-size: 13px;				
			}
		}
  }
}
