.wrapper {
	
	border: 1px solid green;
	border-radius: 5px;
	padding: 20px;	  

  h3 {
    margin-bottom: 40px;
  }

  .card {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    width: 400px;
    &:hover {
      background-color: #eee;
    }
    .cardLogo {
      width: 40px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .details {
      p:first-child {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 2px;
      }
      p:nth-child(2) {
        font-size: 14px;
        color: #595959;
      }
    }
    .expire {
      margin-left: auto;
      font-size: 14px;
    }
    p {
      margin-right: 5px;
      &:last-child {
        margin-left: auto;
      }
    }
  }
}
