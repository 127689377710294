.App {
  margin-top: 20px;
}
body {
	overflow-x:hidden;
	width:100%;
}
#root {
	/* overflow-x:hidden;  */
	width:100%;
}
.MuiDialog-paper {
	overflow-x:hidden;	
}

body, h1, h2, h3, h4, p {
	color: #444;
	font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
}
a {
	text-decoration: none;
	/* color: #30b33e; */
	color: var(--primary-color);
}
.title {
	text-align: center;
	margin-bottom: 20px;
	font-weight: bold;
}
.title2 {
	text-align: center;
	margin-top: 20px;	
	margin-bottom: 20px;	
	font-weight: bold;	
}
.form-control {
	font-weight: 500 !important;
	font-size: 16px !important;
}
.btn-group-lg>.btn, .btn-lg {
    padding: 0.5rem rem 1rem  !important;
    font-size: 16px !important;
    border-radius: 0.3rem !important;
}
.btn {
	padding: 10px;	
	border-style: none;
}
.btn-primary {
    color: #fff !important;
    /*background-color: #30b33e !important;*/
	background-color: var(--primary-color) !important;
    /*border-color: #30b33e !important;*/
	border-color: var(--primary-color) !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #29a22d;
  /* border-color: #29a22d;	 */
}

@media (min-width: 600px) { 
.stepsWrapper{
  width: 100%;
  max-width: 420px;
  margin: 0 auto; 
}
}
@media (max-width: 600px) { 
.stepsWrapper{
  padding: 10px;
  margin: 0 auto;
}
}

.multi-step {
  height: 500px;
  display: flex;
  flex-direction: column;
}
.footer-buttons {
  margin-top: 20px;	/* auto */
  /* position: fixed; */
  bottom: 0;	
  left: 0;
  width: 100%;
  padding-bottom: 20px;  
  padding-top: 10px;
  /* padding-left: 30px; */
}

.step-progress {
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
}
ol.progtrckr {
  list-style-type: none;
  padding: 0;
  white-space: nowrap;
  text-align: center;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 0rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 0rem;
}
/* @media (max-width: 1000px) {  */
  .progtrckr li span {
    display: none;
  }
/* } */
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
/* @media (max-width: 1000px) { */
  .progtrckr em {
    display: none;  
  }
/* } */
ol.progtrckr li.progtrckr-todo {
  color: #eee; /* silver; */
  border-bottom: 12px solid #eee;
  width:50px;  
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 12px solid #eee;
  width:50px;  
}
ol.progtrckr li.progtrckr-done {
  /* color: #30b33e; */
  color: var(--primary-color);
  border-bottom: 12px solid var(--primary-color);
  width:50px;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
	display:none;
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: #eee;  /* silver; */
  background-color: white;
  width: 1.2em;
  line-height: 0em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  /* color: #ff4500; */
  color: var(--primary-color);
}
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #eee;
  width: 1.2em;
  line-height: 0em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  /* color: #ff4500; */
  color: var(--primary-color);
}
ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: var(--primary-color);
  width: 1.2em;
  line-height: 0em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}


/* ul li navi layout */
.navi-horiz {
  padding: 10px;	
  text-align: center;
  /* display: table; */
  width: 100%;
  display: flex;
  justify-content: center;  
}
.navi-horiz ul { 
	display: table-row; 
}
.navi-horiz li { 
	display: table-cell; 
	width: 110px; 
	padding:10px;	
}
.navi-horiz li a { 
	display: block; 
	border: 1px solid #000; 
}

.navi-vert {
  padding: 10px;	
  text-align: center;
  display: table;
  width: 50%; 
  margin: 0 auto;  
}
.navi-vert ul { 
	display: table-row; 
	list-style-type: none; 
}
.navi-vert li { padding: 10px;}
.navi-vert li a { display: block; border: 1px solid #000; padding: 10px; margin: 10px}

/* buttons */
.pull-right {
	margin-left: 10px;
	/* float: right; */
}

/* box und rect */
.box {
	width: 160px;
	height: 160px;	
	border: 1px solid #eee;
	border-radius: 8px;
}
@media (max-width: 600px) { 
.box {
	width: 120px;
	height: 120px;	
	border-radius: 5px;	
	background-color: #f00
}
}
.box.selected{
	border: 5px solid var(--primary-color);
	border-radius: 8px;	
}
@media (max-width: 600px) { 
.box.selected{
	border-radius: 5px;	
}
}
.rect {
	max-width: 500px;
	/* height: 30px; */
	border: 1px solid #eee;
	border-radius: 8px;	
}
.rect.selected{
	border: 5px solid var(--primary-color);
	border-radius: 8px;	
}

.form-group {
	margin-top: 10px;	
	margin-bottom: 10px;
}

.form-text.error {
	color: #f00; 
	font-weight: bold
}

.form-result {
	margin-top: 10px;
	padding: 20px;
	background: #c5ffc5;
	border-radius: 8px;	
	line-height: 2;
}
.label {
	color: #444;
}
.result {
	font-weight: 700 !important;	
}

.main-text {
	font-weight: bold;
	color: var(--primary-color);
}

.mini-text {
	font-size: 11px;
	color: #888;
}

.spinner {
    animation: spin infinite 1s linear;
	/* color: #30b33e; */
	color: var(--primary-color);
	padding: 20px;
    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
 }
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.MuiCard-root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
}
.MuiCardActions-root{
   margin-top: auto;
}

.MuiButton-textPrimary {
}


