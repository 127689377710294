.wrapper {
	display: flex;
	width: 100%;
	justify-content: left;
	border: 1px solid green;
	border-radius: 5px;
	background-color: #fff;

	form {
		background-color: white;
		width: 100%;
		border-radius: 10px;
		max-height: 420px;
		width: 400px;
		padding: 20px 30px;
   

		.title {
			width: 100%;
			text-align: left;
			color: #383838;
			font-weight: 500;
			margin-bottom: 30px;
		}

		.row {
			margin: 15px 0;
			width: 100%;

			label {
				display: block;
				margin-bottom: 8px;
				font-size: 13px;
				font-weight: 500;
				color: #808080;
			}
			input {
				width: 100%;
				border: 1px solid #bfbfbf;
				height: 33px;
				border-radius: 5px;
				padding-left: 5px;

				&:focus {
					outline: none;
					box-shadow: 5px 5px 10px #eeeeee;
				}
			}
		}
		
		.productImage {
			width: 128px;
		}

		.btnContainer {
			width: 100%;
			display: flex;
			justify-content: flex-begin;

			button {
				cursor: pointer;
				width: 150px;
				border: none;
				height: 40px;
				color: white;
				font-weight: 500;
			}
		}
	}
}
