// @import "../../utils/colors.scss";

.wrapper {
	padding: 30px;
	border: 1px solid green;
	border-radius: 5px;
	background-color: #fff;	
	form {
		.card {
			border: 1px solid #d1d1d1;
			padding: 20px 20px 5px 20px;
			border-radius: 5px;
			position: relative;
			width: 500px;
			margin-bottom: 20px;

			.icon {
				img {
					width: 80px;
					height: auto;
				}
				position: absolute;
				right: 20px;
			}
		}

		.row {
			margin-bottom: 20px;
			label {
				display: block;
				margin-bottom: 5px;
				font-size: 14px;
				color: #7d7d7d;
			}
			.cvcInput {
				border: 1px solid #d4d4d4;
				width: 120px;
				border-radius: 5px;
				padding: 8px;
			}
		}
		.row.col {
			display: flex;
			div:first-child {
				margin-right: 100px;
			}
		}
	}
}

.cvcError {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}
